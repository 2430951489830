import React from 'react'
import ReadMoreButton from "./ReadMoreButton/ReadMoreButton"

const ReadMore = ({text, openReadMoreHandler}) => {
    const textLength = text.length
    const limit = 103


    const getLimitedText = (text, limit) => {
        const limitWithoutReadMore = limit
        return text.substring(0, limitWithoutReadMore)
    }

    const onClickHandler = () => {
        openReadMoreHandler(true)
    }

    return (
        <div>
            {textLength > limit ?
                <p>{getLimitedText(text, limit)}<ReadMoreButton onClickHandler={onClickHandler}/></p> : text}
        </div>
    );
};

export default ReadMore
