export const translations = {
  en: {
    privateList: 'This wishlist is private',
    notRequestGifts: 'This couple did not \n request any gifts yet',
    success: 'Success!',
    giftBought: 'The gift has been bought',
    confirmationEmail: 'You will receive confirmation email soon',
    done: 'Done',
    amountToDeposit: 'Amount to deposit',
    // buyTheGift: 'Buy the gift'
    // theMountIsEqual: 'The amount is equal ',
    // forTheGift: 'for the gift.',
    // leftToDeposit: 'left to deposit. \n',
    // enterTheAmount: 'Enter the amount what you want to pay.',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    require: 'Require',
    invalidEmail: 'Invalid email',
    confirm: 'Confirm',
    useSocialMedia: 'or use social media to confirm',
    // selectQuantity: 'Select quantity of the gift',
    quantity: 'Quantity',
    confirmContributed: 'Confirm that you contributed',
    confirmBought: 'Confirm that you bought',
    purchased: 'Purchased',
    // contributed: 'Contributed',
    price: 'Price',
    requested: 'Requested',
    stillNeeds: 'Still needs',
    daysLeft: 'days left',
    weddingThoughts: 'Do you have wedding thoughts?',
    downloadApp: 'Then download Wedme! A wedding tool that simplifies planning \n and at the same time gives you answers to all questions about the wedding.',
    toShop: 'Go to shop',
    confirmYouBoughtAGift: 'Confirm you bought a gift',
    thisIsAGroupGift: 'This is a group gift',
    contributeToGift: 'Contribute to the gift',
    contribute: 'Contribute',
    stillMissing: 'Still missing',
    // required: 'Required',
    giftContributed: 'You contributed to the gift',
    received: 'Received',
    confirmContribution: 'Confirm contribution',
    makeAContribution: 'Make a contribution',
    readMore: 'Read more'
  },
  se: {
    privateList: 'Önskelistan är inte publicerad',
    notRequestGifts: 'Bröllopsparet har ännu inte \n önskat sig några gåvor',
    success: 'Det gick bra!',
    giftBought: 'Gåvan är köpt',
    confirmationEmail: 'Du kommer inom kort att få en bekräftelse via e-post',
    done: 'Klar',
    amountToDeposit: 'Belopp som ska sättas in',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    email: 'E-post',
    require: 'Obligatoriskt',
    invalidEmail: 'Ogiltig e-postadress',
    confirm: 'Bekräfta',
    useSocialMedia: 'Eller använd sociala medier för att bekräfta',
    quantity: 'Antal',
    confirmContributed: 'Bekräfta att du har bidragit',
    confirmBought: 'Bekräfta att du har köpt',
    purchased: 'Köpt',
    price: 'Pris',
    requested: 'Önskar',
    stillNeeds: 'Saknas',
    daysLeft: 'dagar kvar',
    weddingThoughts: 'Går ni i bröllopstankar?',
    downloadApp: 'Ladda då ner Wedme! Ett bröllopsverktyg som förenklar planeringen \n och samtidigt ger dig svar på alla frågor kring bröllopet.',
    toShop: 'Till butiken',
    confirmYouBoughtAGift: 'Bekräfta köp av gåva',
    thisIsAGroupGift: 'Bidra med valfri summa',
    contributeToGift: 'Bidra till gåvan',
    contribute: 'Bidra',
    stillMissing: 'Saknas',
    giftContributed: 'Du bidrog till gåvan',
    received: 'Tagit emot',
    confirmContribution: 'Bekräfta bidrag',
    makeAContribution: 'Ge ett bidrag',
    readMore: 'Läs mer'
  },
  sv: {
    privateList: 'Önskelistan är inte publicerad',
    notRequestGifts: 'Bröllopsparet har ännu inte \n önskat sig några gåvor',
    success: 'Det gick bra!',
    giftBought: 'Gåvan är köpt',
    confirmationEmail: 'Du kommer inom kort att få en bekräftelse via e-post',
    done: 'Klar',
    amountToDeposit: 'Belopp som ska sättas in',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    email: 'E-post',
    require: 'Obligatoriskt',
    invalidEmail: 'Ogiltig e-postadress',
    confirm: 'Bekräfta köp',
    useSocialMedia: 'Eller använd sociala medier för att bekräfta',
    quantity: 'Antal',
    confirmContributed: 'Bekräfta att du har bidragit',
    confirmBought: 'Bekräfta att du har köpt',
    purchased: 'Köpt',
    price: 'Pris',
    requested: 'Önskar',
    stillNeeds: 'Saknas',
    daysLeft: 'dagar kvar',
    weddingThoughts: 'Går ni i bröllopstankar?',
    downloadApp: 'Ladda då ner Wedme! Ett bröllopsverktyg som förenklar planeringen \n och samtidigt ger dig svar på alla frågor kring bröllopet.',
    toShop: 'Till butiken',
    confirmYouBoughtAGift: 'Bekräfta köp av gåva',
    thisIsAGroupGift: 'Bidra med valfri summa',
    contributeToGift: 'Bekräfta bidrag',
    contribute: 'Bidra',
    stillMissing: 'Saknas',
    giftContributed: 'Du bidrog till gåvan',
    received: 'Tagit emot',
    confirmContribution: 'Bekräfta bidrag',
    makeAContribution: 'Ge ett bidrag',
    readMore: 'Läs mer'
  }
}
