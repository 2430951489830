import cx from "classnames"
import React, { useEffect, useState } from 'react'
import { giftTypes } from '../../../../consts'
import { ReactComponent as ArrowDown } from '../../../../PurchaseGiftModal/BuyTheGift/arrow-ios-downward-outline.svg'
import { ReactComponent as ArrowUp } from '../../../../PurchaseGiftModal/BuyTheGift/arrow-ios-upward-outline.svg'
import { t } from "../../../../utils"
import { ReactComponent as Cart } from "./cart.svg"
import styles from "./Info.module.scss"

const Info = ({ purchased, id, type, websiteUrl, onConfirmClickHandler, stillNeeds, quantity }) => {
  const [quantityValue, setQuantityValue] = useState(1)

  const increaseInputValue = (e) => {
    e.stopPropagation()
    if (quantityValue === '') {
      setQuantityValue(1)
      return null
    }
    const newValue = parseInt(quantityValue) + 1
    newValue > stillNeeds ? onChangeHandler(stillNeeds.toString()) : onChangeHandler(newValue.toString())
  }
  const decreaseInputValue = (e) => {
    e.stopPropagation()
    const newValue = parseInt(quantityValue) - 1
    newValue > 0 ? onChangeHandler(newValue.toString()) : onChangeHandler('1')
  }
  const onChangeHandler = (value) => {
    if (value > stillNeeds) return stillNeeds
    setQuantityValue(value)
  }
  const getConfirmButtonLabel = () => {
    if(type === giftTypes.LIMITED_PRICE || type === giftTypes.UNLIMITED_PRICE) return t().confirmContribution
    if(type === giftTypes.LIMITED_QUANTITY)  return t().confirm
    return t().contributeToGift
  }

  const getToShopButtonLabel = () => {
    if(type === giftTypes.LIMITED_PRICE || type === giftTypes.UNLIMITED_PRICE) return t().makeAContribution
    return t().toShop
  }

  const shouldDisplayInput = () => {
    if (type === giftTypes.LIMITED_QUANTITY && quantity === 1) return false
    return type === giftTypes.LIMITED_QUANTITY
  }
  if (purchased) return <div></div>
  return (
    <div className={styles.buttonsWrapper}>
      <div className={cx(styles.confirmButtonWrapper, { [styles.withInput]: shouldDisplayInput() })}>
        {!purchased &&
        <button className={styles.confirmButton}
                onClick={(e) => {
                  e.stopPropagation()
                  onConfirmClickHandler(id, type, quantityValue)
                }}
                disabled={type === giftTypes.LIMITED_QUANTITY && (quantityValue === '' || parseInt(quantityValue) === 0)}>
          {getConfirmButtonLabel()}
        </button>}
        {shouldDisplayInput() && <div className={styles.popupInput}>
          <input id='quantity'


                 type='number'
                 value={quantityValue === '0' ? '' : quantityValue}
                 onChange={(e) => onChangeHandler(e.target.value)}
                 inputMode='decimal'
                 min={1}
                 max={stillNeeds}
                 onClick={(e) => e.stopPropagation()}
          />
          <ArrowUp className={styles.inputArrowUp} onClick={increaseInputValue}/>
          <ArrowDown className={styles.inputArrowDown} onClick={decreaseInputValue}/>
        </div>}
      </div>

      {websiteUrl && <div>
        <div className={styles.decorativeLine}/>
        <a className={styles.toShopButton} href={websiteUrl.startsWith('http') ? websiteUrl : 'https://' + websiteUrl}
           target='_blank'
           rel="noreferrer"
           onClick={(e) => e.stopPropagation()}>
          <Cart className={styles.cart}/>
          <span className={styles.toShopButtonName}>{getToShopButtonLabel()}</span>
        </a>
      </div>}
    </div>
  )
}

export default Info
