import React, { useState } from 'react'
import { giftTypes } from '../../consts'
import GiftDescription from '../../GiftList/GiftItem/components/GiftDescription/GiftDescription'
import { getContributionForPrice, t } from '../../utils'
import styles from '../PurchasedGiftModal.module.scss'
import no_image_placeholder from "./../../GiftList/GiftItem/components/ImageWrapper/no_image_placeholder.png"
import { ReactComponent as ArrowDown } from './arrow-ios-downward-outline.svg'
import { ReactComponent as ArrowUp } from './arrow-ios-upward-outline.svg'
import { ReactComponent as Cart } from "./cart.svg"

export const BuyTheGift = ({ onClickHandler, selectedGift: { description, websiteUrl, imageUrl, title, stillNeeds, purchased, price, currencySymbol, quantity, giftContributors, type } }) => {

  const [quantityValue, setQuantityValue] = useState(1)

  const changeQuantity = (value) => {
    const newValue = parseInt(quantityValue) + parseInt(value)
    if (newValue > stillNeeds) {
      setQuantityValue(stillNeeds)
      return
    }
    if (newValue > 0) setQuantityValue(newValue)
  }
  const inputChangeHandler = (inputValue) => {
    let newValue = parseInt(inputValue) > stillNeeds ? stillNeeds : inputValue
    newValue = parseInt(inputValue) < 0 ? 0 : newValue
    setQuantityValue(newValue)
  }
  const getCorrectValue = () => {
    if (stillNeeds === 1) return 1
    return quantityValue
  }
  return (
    <div className={styles.popupContent}>
      <img alt="" className={styles.image} src={imageUrl || no_image_placeholder}/>
      <div className={styles.popupContentTitle}>{title}</div>
      <div className={styles.separator}/>
      <GiftDescription price={price} contributors={giftContributors} currencySymbol={currencySymbol}
                       stillNeeds={stillNeeds} purchased={purchased} quantity={quantity} shouldDisplayProgress={quantity > 1}/>
      {(type === giftTypes.LIMITED_QUANTITY) && quantity > 1 && <div>
        <p className={styles.listItemInfoDescriptionPrice}>
          {`${t().requested}: ${quantity}`}
        </p>
        <p className={styles.listItemInfoDescriptionAmount}>
          {`${t().stillNeeds}: ${stillNeeds}`}
        </p>
        <div className={styles.separator}/>
      </div>}



      {!!description.length && <>
        <div className={styles.popupDescription}>{description}</div>
        <div className={styles.separator}/>
      </>}
      {/*{stillNeeds !== 1 && <div className={styles.popupDescription}>{t().selectQuantity}</div>}*/}
      {stillNeeds !== 1 && !purchased && <div className={styles.popupInput}>
        <label form='quantity'>{t().quantity}</label>
        <input id='quantity'
               type='number'
               value={quantityValue}
               onChange={(e) => inputChangeHandler(e.target.value)}
               inputMode='decimal'
               autoComplete="off"
               max={stillNeeds}
               min={1}/>
        <ArrowUp className={styles.inputArrowUp} onClick={() => changeQuantity(1)}/>
        <ArrowDown className={styles.inputArrowDown} onClick={() => changeQuantity(-1)}/>
      </div>}
      <div className={styles.buttonWrapper}>
        {!purchased &&
        <button disabled={quantityValue < 1 && stillNeeds !== 1} onClick={() => onClickHandler(getCorrectValue())}
                className={styles.popupButton}>{t().confirmYouBoughtAGift}</button>}
      </div>
      {websiteUrl && <div>
        <div className={styles.decorativeLine}/>
        <a className={styles.toShopButton} href={websiteUrl.startsWith('http') ? websiteUrl : 'https://' + websiteUrl}
           target='_blank'
           rel="noreferrer"
           onClick={(e) => e.stopPropagation()}>
          <Cart className={styles.cart}/>
          <span className={styles.toShopButtonName}>{t().toShop}</span>
        </a>
      </div>}
    </div>
  )
}
