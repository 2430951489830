import React from 'react'
import { marketLinks } from '../consts'
import { t } from '../utils'
import { ReactComponent as Appstore } from './appstore.svg'
import { ReactComponent as GooglePlay } from './google-play-store-badge-en.svg'
import styles from './styles.module.scss'


export const Footer = () => {
  return (
    <div className={styles.wishlistFooterContainer}>
      <div className={styles.wishlistFooterWrapper}>
        <div className={styles.wishlistFooterTitle}>{t().weddingThoughts}</div>
        <div className={styles.wishlistFooterDescription}>{t().downloadApp}</div>
        <div className={styles.wishlistFooterLinksWrapper}>
          <a href={marketLinks.appStore} target='_blank' rel="noreferrer"><Appstore/></a>
          <a href={marketLinks.playMarket} target='_blank' rel="noreferrer"><GooglePlay/></a>
        </div>
      </div>
    </div>
  )
}
