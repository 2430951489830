import React from 'react'
import styles from "./GiftTitle.module.scss"
import MultiClamp from 'react-multi-clamp'

const GiftTitle = ({title}) => {
    return (
        <div className={styles.listItemInfoTitleContainer}>
            <MultiClamp className={styles.listItemInfoTitle} ellipsis="..." clamp={2}>
                {title}
            </MultiClamp>
        </div>
    )
}


export default GiftTitle
