import React from 'react'
import styles from '../DaysLeft.module.scss'
import { ReactComponent as Logo } from '../wedme-logotype-a-green.svg'

const WishlistHeader = () => {
  return (
    <div className={styles.wishlistHeader}><Logo className={styles.logoImg}/></div>
  )
}

export default WishlistHeader
