import format from 'date-fns/format'
import React from 'react'
import { daysBetween } from '../utils'
import styles from './DaysLeft.module.scss'
import { ReactComponent as BackgroundIconRight } from './graphic-2.svg'
import { ReactComponent as BackgroundIconLeft } from './group-4.svg'
import ProgressMeter from './ProgressMeter/ProgressMeter'
import WishlistHeader from './WishlistHeader/WishlistHeader'
import ReadMore from "./ReadMore/ReadMore";


export const DaysLeft = ({ data, openReadMoreHandler }) => {

  const now = Date.now()
  const datesDiff = (data) => {
    if (data === null) return 0

    return daysBetween(new Date(data.date * 1000), new Date(data.createdAd))
  }

  const weddingAlreadyHappend = (weddingDate) => {
    return weddingDate.getTime() < now
  }
  const daysFromCreationToWedding = datesDiff(data)


  const getDaysToWedding = (data) => {
    if (weddingAlreadyHappend(new Date(data.date * 1000))) {
      return 0
    } else {
      return daysBetween(new Date(data.date * 1000), new Date(now))
    }
  }
  const weddingAproachPercents = (daysFromCreationToWedding - getDaysToWedding(data)) / daysFromCreationToWedding * 100
  return (
    <div className={styles.wishlistInfoContainer}>
      <WishlistHeader/>
      {data && <>
        <div className={styles.wishlistInfoWrapper}>
          <div className={styles.title}>{`${data?.bridename} & ${data?.groomname}`}</div>
          <div className={styles.date}>
            {format(new Date(data?.date * 1000), 'd MMMM yyyy')}
          </div>
          {<ProgressMeter radius={106} stroke={4} progress={weddingAproachPercents}
                          daysToWedding={getDaysToWedding(data)}/>}
          <div className={styles.extraInfo}>
            {data?.text && <ReadMore openReadMoreHandler={openReadMoreHandler} text={data.text}/>}
          </div>
        </div>
        <div className={styles.backgroundIconsWrapper}>
          <BackgroundIconLeft className={styles.backgroundIcon}/>
          <div className={styles.emptyContainer}/>
          <BackgroundIconRight className={styles.backgroundIcon}/>
        </div>
      </>}
    </div>
  )
}
