import React from 'react'
import styles from './ProgressLine.module.scss'

const ProgressLine = ({quantity, stillNeeds, progress, shouldDisplay}) => {
  return (
    <div className={styles.progressWrapper}>
      {shouldDisplay && <div className={styles.measuringProgress}>
        <div className={styles.progress} style={{
          width: `${progress}%`
        }}/>
      </div>
      }
    </div>
  )
}

export default ProgressLine
