import cx from 'classnames'
import { subscribe } from 'on-screen-keyboard-detector'
import React, { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import { t } from '../utils'
import { ReactComponent as Cross } from './cross.svg'
import { ReactComponent as Arrow } from './left.svg'
import styles from './Modal.module.scss'

export const Modal = ({ isOpen, children, confirmSection, setModalIsOpen, shouldDisplayArrow }) => {
  const size = useWindowSize()
  const [isContributionType, setIsContributionType] = useState(false)
  const [confirmSectionIsOpen, setConfirmSectionIsOpen] = useState(false)
  const [keyboardDetected, setKeyboardDetected] = useState(false)
  const modalRef = useRef()
  const scroll = useRef()
  const unsubscribe = subscribe(visibility => {
    setKeyboardDetected(visibility !== "hidden")
  })
  useOnClickOutside(modalRef, () => setModalIsOpen(false))
  useEffect(() => {
    return unsubscribe()
  }, [unsubscribe])

  useEffect(() => {
    if (!isOpen) setConfirmSectionIsOpen(false)
  }, [isOpen])


  useEffect(() => {
    try {
      if (isOpen) {
        scroll.current.scrollToBottom()
        document.body.classList.add('preventScroll')
      } else {
        document.body.classList.remove('preventScroll')
      }
    } catch (e) {
      console.error(e)
    }
  }, [isOpen])


  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      try {
        window.addEventListener("resize", handleResize)
        handleResize()
      } catch (e) {
        console.error(e)
      }
    }, [])
    return windowSize
  }


  return (
    <div className={cx(styles.popupContainer, { [styles.popupContainerVisible]: isOpen })}
         onScroll={(e) => e.stopPropagation()}>
      {isOpen && <div ref={modalRef} className={cx(styles.popup)}
                      style={{ pointerEvents: isOpen ? 'auto' : 'unset' }}>
        <Scrollbars ref={scroll}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={keyboardDetected ? size.height : (size.height * 0.8)}>
          {confirmSectionIsOpen
            ? (
              <>
                <div className={styles.titleWrapper}>
                  {shouldDisplayArrow &&
                  <div className={styles.arrowBack} onClick={() => setConfirmSectionIsOpen(false)}><Arrow/></div>}
                  <div className={styles.popupTitle}>{isContributionType ? t().confirmContributed : t().confirmBought}</div>
                </div>
                <div className={styles.closeWrapperWithoutArrow}>
                  <div className={styles.close} onClick={() => setModalIsOpen(false)}><Cross/></div>
                </div>
                {confirmSection}
              </>)
            : (
              <>
                <div className={styles.closeWrapper}>
                  <div className={styles.close} onClick={() => setModalIsOpen(false)}><Cross/></div>
                </div>
                {children(setConfirmSectionIsOpen, setIsContributionType)}
              </>)}
        </Scrollbars>
      </div>}
    </div>
  )
}
