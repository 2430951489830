import cx from 'classnames'
import React from 'react'
import { t } from '../../../../utils'
import styles from "./ImageWrapper.module.scss"
import no_image_placeholder from "./no_image_placeholder.png"
import Purchased from "./Purchased.svg"


const ImageWrapper = ({ imageUrl, purchased }) => {
  return (
    <div className={styles.imageWrapper}>
      {!purchased && <div className={cx({ [styles.listItemImage]: true, [styles.placeholder]: !imageUrl })}
                          style={{ 'backgroundImage': `url(${imageUrl || no_image_placeholder})` }}/>}
      {purchased && <div className={styles.labelWrapper}>
          <div className={cx({ [styles.listItemImage]: true, [styles.placeholder]: !imageUrl ,[styles.purchasedGift] : purchased})}
               style={{ 'backgroundImage': `url(${imageUrl || no_image_placeholder})` }}/>
        <img alt='' className={styles.purchasedLabel}
             src={Purchased}/>
        <p className={styles.purchasedText}>{t().purchased.toUpperCase()}</p>
      </div>}
    </div>
  )
}

export default ImageWrapper
