import cx from 'classnames'
import React, {useEffect, useState} from 'react'
import {giftTypes} from '../../consts'
import GiftDescription from '../../GiftList/GiftItem/components/GiftDescription/GiftDescription'
import {t} from '../../utils'
import styles from '../PurchasedGiftModal.module.scss'
import no_image_placeholder from "./../../GiftList/GiftItem/components/ImageWrapper/no_image_placeholder.png"
import { ReactComponent as Cart } from "./cart.svg"

export const GiftContributing = ({onClickHandler, selectedGift: {description = '', websiteUrl, imageUrl, price, title, leftToDeposit, purchased, quantity, stillNeeds, currencySymbol, giftContributors, type}}) => {
    const [giftAmount, setGiftAmount] = useState(undefined)

    useEffect(() => {
        setGiftAmount(undefined)
        return () => setGiftAmount(undefined)
    }, [])


    const inputChangeHandler = (inputValue) => {
        if (Number.isNaN(Number(inputValue))) {
            setGiftAmount('')
            return
        }
        let newValue = ''
        let hasSeparator = false
        let parts = []
        const inputValueAsNumber = Number(inputValue)
        if (inputValue.includes(',')) {
            hasSeparator = true
            parts = inputValue.split(',')
        }
        if (inputValue.includes('.')) {
            hasSeparator = true
            parts = inputValue.split('.')
        }

        if (hasSeparator && parts[1] === '') {
            setGiftAmount(inputValue)
        } else {
            newValue = (leftToDeposit > 0 && inputValueAsNumber > leftToDeposit) ? leftToDeposit : inputValueAsNumber
            newValue = inputValueAsNumber < 0 ? 0 : newValue
            if (hasSeparator && parts[1].length > 2) {
                newValue = Math.trunc(newValue * 100) / 100
            }
            if (inputValue === '') newValue = ''
            setGiftAmount(newValue.toString())
        }


    }
    return (
        <div className={styles.popupContent}>
            <img alt="" className={styles.image} src={imageUrl || no_image_placeholder}/>
            <div className={styles.popupContentTitle}>{title}</div>
            <div className={styles.separator}/>
            <GiftDescription price={price} contributors={giftContributors} currencySymbol={currencySymbol}
                             stillNeeds={stillNeeds} purchased={purchased} quantity={quantity}
                             shouldDisplayProgress={type === giftTypes.LIMITED_QUANTITY || type === giftTypes.LIMITED_PRICE}
                             type={type}/>
            <div className={styles.separator}/>
            {!!description.length && (<>
                <div className={styles.popupDescription}>{description}</div>
                <div className={styles.separator}/>
            </>)}
            {!purchased && <div className={cx(styles.popupInput)}>
                <label form='amount'>{t().amountToDeposit} ({currencySymbol})</label>
                <input id='amount'
                       type='number'
                       inputMode='decimal'
                       lang="en_US"
                       pattern="[0-9]+([.][0-9]+)?"
                       step="0.01"
                       autoComplete="off"
                       value={giftAmount}
                       onChange={(e) => inputChangeHandler(e.target.value)}
                />
                {/*<ArrowUp className={styles.inputArrowUp} onClick={() => changeQuantity(1)}/>*/}
                {/*<ArrowDown className={styles.inputArrowDown} onClick={() => changeQuantity(-1)}/>*/}
            </div>}
            <div className={styles.buttonWrapper}>
                {!purchased && <button disabled={Number.isNaN(Number(giftAmount)) || Number(giftAmount) === 0}
                                       onClick={() => onClickHandler(giftAmount)}
                                       className={styles.popupButton}>{t().contribute}</button>}
            </div>
            {websiteUrl && <div>
                <div className={styles.decorativeLine} />
                <a className={styles.toShopButton} href={websiteUrl.startsWith('http') ? websiteUrl : 'https://' + websiteUrl}
                    target='_blank'
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}>
                    <Cart className={styles.cart} />
                    <span className={styles.toShopButtonName}>{t().toShop}</span>
                </a>
            </div>}
        </div>)
}
