import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/database'
import { firebaseConfig } from './consts'
import { translations } from "./translation"

firebase.initializeApp(firebaseConfig)
export const database = firebase.database().ref('posts/')
export const auth = firebase.auth()
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const facebookProvider = new firebase.auth.FacebookAuthProvider()
facebookProvider.addScope('email')
export const fanalytics = firebase.analytics()


export const daysBetween = (date1, date2) => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  let differenceMs = date1.getTime() > date2.getTime() ? date1.getTime() - date2.getTime() : date2.getTime() - date1.getTime()
  return Math.ceil(differenceMs / ONE_DAY)
}


export const getSortedGifts = (gifts) => gifts.sort((obj1, obj2) => {
  if (obj1.orderNumber !== obj2.orderNumber) {
    return obj1.orderNumber ?? 0 - obj2.orderNumber ?? 0
  }
  return new Date(obj1.createdAt).getTime() - new Date(obj2.createdAt).getTime()
})


export const t = () => {
  const { navigator } = window
  let activeLanguage = 'en'
  if (navigator.language.includes('sv')) {
    activeLanguage = 'sv'
  }
  return translations[activeLanguage]
}

export const getContributionForPrice = (contributors) => {
  return contributors.reduce((acc, contributor) => {
    return acc + parseFloat(contributor.price)
  }, 0.0)
}

export const getCompletionProgress = (contributors, quantity, stillNeeds, price) => {
  if (parseInt(quantity) === 0) {
    return getContributionForPrice(contributors) / price * 100
  }
  return (quantity - stillNeeds) / quantity * 100
}
