import React from 'react'
import { GiftItem } from './GiftItem/GiftItem'
import styles from './styles.module.scss'

export const GiftList = ({ onClickHandler, data, isListView, onConfirmClickHandler }) => {
  let purchased = []

  const giftsToBuy = data?.sort((a, b) => (a?.orderNumber > b?.orderNumber) ? 1 : -1)
    .map((gift) => {
      if (gift.purchased) {
        purchased.push(<GiftItem key={gift.id} onClickHandler={() => void 0}
                                 data={gift} isListView={isListView}/>)
        return null
      } else {
        return <GiftItem key={gift.id} onClickHandler={onClickHandler}
                         onConfirmClickHandler={onConfirmClickHandler}
                         data={gift} isListView={isListView}/>
      }
    })


  return <div className={styles.listContainer}>
    {giftsToBuy}
    {purchased}
  </div>
}
