import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { ReactComponent as SuccessIcon } from './checked.svg'
import { apiUrl, giftTypes } from './consts'
import { DaysLeft } from './DaysLeft/DaysLeft'
import { ReactComponent as Logo } from './DaysLeft/wedme-logotype-a-green.svg'
import WishlistHeader from './DaysLeft/WishlistHeader/WishlistHeader'
import { Footer } from './Footer/Footer'
import { GiftList } from './GiftList/GiftList'
import { Modal } from './Modal/Modal'
import { BuyTheGift } from './PurchaseGiftModal/BuyTheGift/BuyTheGift'
import { prepareWishlistData } from './PurchaseGiftModal/BuyTheGift/utils'
import { ConfirmSection } from './PurchaseGiftModal/ConfirmSection/ConfirmSection'
import { GiftContributing } from './PurchaseGiftModal/GiftContributing/GiftContributing'
import { Success } from './PurchaseGiftModal/Success/Success'

import styles from './styles.module.scss'
import { fanalytics, getSortedGifts, t } from './utils'


const Wishlist = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const { id } = useParams()
  const giftId = urlParams.get('giftId')
  const rejectionToken = urlParams.get('rejectionToken')

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false)
  const [listKey, setListKey] = useState(1)
  const [giftModalIsOpen, setGiftModalIsOpen] = useState(false)
  const [isPrivateList, setIsPrivateList] = useState(false)
  const [modalSource, setModalSource] = useState(null)

  const [modalContentType, setModalContentType] = useState(null)
  const [giftList, setGiftList] = useState(null)
  const [selectedGift, setSelectedGift] = useState(null)
  const [giftValues, setGiftValues] = useState(null)
  const [weddingData, setWeddingData] = useState(null)
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)


  const initWishlist = async () => {
    try {
      await fetch(`${apiUrl}wishlist/wedding/${id}`)
        .then(response => response.json())
        .then(({ wishlist, ...rest }) => {
          setGiftList(getSortedGifts(prepareWishlistData(wishlist)))
          setWeddingData({ ...rest })
        })
    } catch (e) {
      setIsPrivateList(true)
    }
  }

  useEffect(() => {
    if (id) initWishlist()
    else if (giftId && rejectionToken) fetch(`${apiUrl}${giftId}/reject/contributor/${rejectionToken}`, { method: 'PUT' }).then(_ => {
      try {
        fanalytics.logEvent('gift_reject', {
          gift_id: giftId,
        })
      } catch (e) {
        console.error(e)
      }

    })
  }, [])

  const handleOnGiftClick = (id, modalContentType) => {
    setModalSource('gift')
    const selectedGift = giftList.find((gift) => gift.id === id)
    setSelectedGift(selectedGift)
    setModalContentType(modalContentType)
    setGiftModalIsOpen(!giftModalIsOpen)
  }

  const handleOnConfirmClick = (id, modalContentType, amount) => {
    modalContentType === giftTypes.LIMITED_QUANTITY ? setModalSource('confirm') : setModalSource('gift')
    setGiftValues({ quantity: amount })
    const selectedGift = giftList.find((gift) => gift.id === id)
    setSelectedGift(selectedGift)
    setModalContentType(modalContentType)
    setGiftModalIsOpen(!giftModalIsOpen)
  }

  const handleOnSubmit = ({ lastname, firstname, email }) => {
    const newValues = {
      giftId: selectedGift.id,
      lastname,
      firstname,
      email,
      ...giftValues
    }

    fetch(`${apiUrl}giftContributor`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newValues)
      })
      .then(response => response.json())
      .then(() => {
        setGiftModalIsOpen(false)
        setSuccessModalIsOpen(true)
        fetch(`${apiUrl}wishlist/wedding/${id}`)
          .then(response => response.json())
          .then(({ wishlist }) => {
            setGiftList(getSortedGifts(prepareWishlistData(wishlist)))
            setListKey(listKey + 1)
            try {
              fanalytics.logEvent('gift_bought', {
                gift_id: newValues?.giftId,
                gift_type: newValues?.gift_type,
                gift_title: newValues?.title,
                gift_amount: giftValues?.quantity | giftValues?.price
              })
            } catch (e) {
              console.error(e)
            }
          })
      })
  }

  return <div className={styles.container}>
    {
      weddingData !== null ? <DaysLeft data={weddingData} openReadMoreHandler={setReadMoreIsOpen}/> : <WishlistHeader />
    }
    {!!giftList?.length ? (
      <GiftList key={listKey} onClickHandler={handleOnGiftClick} onConfirmClickHandler={handleOnConfirmClick}
                data={getSortedGifts(giftList)}/>
    ) : (id && !isPrivateList) ? <div className={styles.emptyState}>{t().notRequestGifts}</div> : null}
    {isPrivateList && <div className={styles.emptyState}>{t().privateList}</div>}
    {giftId && rejectionToken && <div className={styles.successMsg}>
      <SuccessIcon className={styles.successIcon}/>
      <div>{t().success}</div>
    </div>}
    <Footer/>

    <Modal
      isOpen={giftModalIsOpen}
      confirmSection={<ConfirmSection onSubmit={handleOnSubmit}/>}
      setModalIsOpen={setGiftModalIsOpen}
    >
      {(setConfirmSectionIsOpen, setIsContributionType) => {
        const popUpContent = {
          [giftTypes.UNLIMITED_QUANTITY]: <BuyTheGift
            selectedGift={selectedGift}
            onClickHandler={(value) => {
              setGiftValues({ quantity: Number(value) })
              setConfirmSectionIsOpen(true)
              setIsContributionType(false)
            }}
          />,
          [giftTypes.LIMITED_QUANTITY]: <BuyTheGift
            selectedGift={selectedGift}
            onClickHandler={(value) => {
              setGiftValues({ quantity: Number(value) })
              setConfirmSectionIsOpen(true)
              setIsContributionType(false)
            }}
          />,
          [giftTypes.UNLIMITED_PRICE]: <GiftContributing
            selectedGift={selectedGift}
            onClickHandler={(value) => {
              setGiftValues({ price: value })
              setConfirmSectionIsOpen(true)
              setIsContributionType(true)
            }}
          />,
          [giftTypes.LIMITED_PRICE]: <GiftContributing
            selectedGift={selectedGift}
            onClickHandler={(value) => {
              setGiftValues({ price: value })
              setConfirmSectionIsOpen(true)
              setIsContributionType(true)
            }}
          />
        }
        if (modalSource === 'confirm') {
          setConfirmSectionIsOpen(true)
          setIsContributionType(modalContentType.includes(giftTypes.UNLIMITED_PRICE, giftTypes.LIMITED_PRICE))
        }
        return popUpContent[modalContentType]
      }}
    </Modal>
    <Modal
      isOpen={successModalIsOpen}
      setModalIsOpen={setSuccessModalIsOpen}
      shouldDisplayArrow={selectedGift?.stillNeeds > 1}
    >
      {() => <Success
        unit={giftValues?.price ? 'price' : 'quantity'}
        confirmSource={selectedGift?.type} closeModal={() => setSuccessModalIsOpen(false)}/>}
    </Modal>
    <Modal
        isOpen={readMoreIsOpen}
        setModalIsOpen={setReadMoreIsOpen}
        shouldDisplayArrow={false}
    >
      {() => <div className={styles.readMoreModalContent}>{weddingData.text}</div>}
    </Modal>
  </div>
}

export default Wishlist
