import React from 'react'
import styles from "./TagsWrapper.module.scss"
import GiftTag from "../GiftTag/GiftTag"
import {t} from "../../../../utils"
import { ReactComponent as Group } from "./group.svg"

const TagsWrapper = ({unlimitedContributions, purchased, quantity, price, giftContributors, currencySymbol, stillNeeds}) => {
    return (
        <div className={styles.listItemTagsWrapper}>
            {purchased && <div className={styles.listItemTagPurchased}>{t().purchased.toUpperCase()}</div>}
            {(price === 0 || quantity === 0) && !purchased &&
                <span className={styles.groupGift}>
                    {t().thisIsAGroupGift}<Group className={styles.groupIcon}/>
                </span>
            }
            {quantity > 1 && !purchased &&
            <GiftTag>
                <span className={styles.grayCenteredUnderline}>
                    {t().requested}: <span className={styles.darkerColor}>{quantity}</span>
                </span>
            </GiftTag>}
            {quantity > 1 && !purchased &&
            <GiftTag>
                <span className={styles.grayCenteredUnderline}>
                    {t().stillNeeds}: <span className={styles.darkerColor}>{stillNeeds}</span>
                </span>
            </GiftTag>}
        </div>
    )
}

export default TagsWrapper
