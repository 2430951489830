import React from 'react'
import { t } from '../../utils'
import styles from '../PurchasedGiftModal.module.scss'
import SuccessIcon from './success.png'

export const Success = ({ closeModal, unit }) => {
  const getSuccessMessage = () => {
    if(unit === 'price') return t().giftContributed
    return t().giftBought
  }
  return <div className={styles.popupContent}>
    <div className={styles.confirmedTitle}>{getSuccessMessage()}</div>
    <div className={styles.confirmedDescription}>{t().confirmationEmail}</div>
    <img alt="" src={SuccessIcon} className={styles.successIcon}/>
    <div className={styles.confirmedButtonWrapper}>
      <button onClick={closeModal} className={styles.popupButton}>{t().done}</button>
    </div>
  </div>
}
