export const giftTypes = {
  LIMITED_PRICE: 'LIMITED_PRICE',
  UNLIMITED_PRICE: 'UNLIMITED_PRICE',
  LIMITED_QUANTITY: 'LIMITED_QUANTITY',
  UNLIMITED_QUANTITY: 'UNLIMITED_QUANTITY'
}

export const marketLinks = {
  appStore: 'https://apps.apple.com/se/app/wedme-br%C3%B6llopsplaneraren/id1451668452',
  playMarket: 'https://play.google.com/store/apps/details?id=com.wedme.android&hl=en'
}

const firebaseConfigurations = {
  stage: {
    apiKey: 'AIzaSyCMgdb6_cvMSnvYmPGi9SkCGeS-B0neH8M',
    authDomain: 'wedme-stage.firebaseapp.com',
    databaseURL: 'https://wedme-stage.firebaseio.com',
    projectId: 'wedme-stage',
    storageBucket: 'wedme-stage.appspot.com',
    messagingSenderId: '717074412335',
    appId: '1:717074412335:web:eb9cd110d9e607977d67da',
    measurementId: 'G-4P8YRW1HER'
  },
  prod: {
    apiKey: "AIzaSyD29Mm-447ys6HHOHCr0t5L99knmKvlWDs",
    authDomain: "wedme-20.firebaseapp.com",
    databaseURL: "https://wedme-20.firebaseio.com",
    projectId: "wedme-20",
    storageBucket: "wedme-20.appspot.com",
    messagingSenderId: "389161915316",
    appId: "1:389161915316:web:d890722bbcda08a35658e1",
    measurementId: "G-LXB0XNHCJL"
  }
}
const apis = {
  stage: 'http://api2.dev.wedme.com/api/gift/',
  prod: 'https://api2prod.wedme.com/api/gift/'
}

const env = 'stage'

export const firebaseConfig = firebaseConfigurations[env]
export const apiUrl = apis[env]
