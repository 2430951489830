import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './fonts/fonts.css'
import Wishlist from './Wishlist/Wishlist'

const App = () => {
  return <Router>
    <Switch>
      <Route path="/:id?" children={<Wishlist/>}/>
    </Switch>
  </Router>
}

export default App
