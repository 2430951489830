import { giftTypes } from '../../consts'
/*
jak quantity = 0 to jest gift w ktorym mozesz sie dorzucic ( contrubite )
jak quantity > 0 to jest ten ktory sie kupuje i schodza do zera jak ktos kupi
i unlimited ma price 0
zaraz dopytam jak odroznic wykupione od nielimitowanych
chyba responsy sa nie zaktualizowane

1. quantity 1
2. quantiti > 1
3. contributed price limited
4. contibuted price unlimited


a to czy kupione czy to :
typ 1 i 2. osoby kupily x sztuk
typ 3 osiagnieto limited price
typ 4 price jest unlimited wiec nigdy nie bedzie kupiony bo mozna sie zrzucsc w nieskknczonosc
 */
const getGiftType = ({quantity, price}) => {
  if (quantity === 0) {
    //contribute
    if(parseInt(price) === 0) {
      //unlimited
      return giftTypes.UNLIMITED_PRICE
    }
    return giftTypes.LIMITED_PRICE
  }
  if (quantity > 0) return giftTypes.LIMITED_QUANTITY

  return null
}

export const prepareWishlistData = (wishlist) => wishlist.map((gift) => {
  const isUnlimitedContrib = (quantity, price) =>  (quantity === 0 && parseInt(price, 10) === 0)
  const isPurchased = (quantity, price, stillNeeds, leftToDeposit) => {
    if(isUnlimitedContrib(quantity, price)) return false
    return quantity ? (quantity !== 0 && stillNeeds === 0) : Number(leftToDeposit) <= 0
  }
  const { quantity, price } = gift
  const giftContributors = gift.giftContributors.filter(({ isRejected }) => !isRejected)
  const stillNeeds = Math.max(0, quantity - giftContributors.reduce((acc, curr) => acc + curr.quantity, 0))
  const leftToDeposit = (Number(price) - giftContributors.reduce((acc, curr) => acc + Number(curr.price), 0)).toString()
  const purchased = isPurchased(quantity, price, stillNeeds, leftToDeposit)
  const unlimitedContributions = isUnlimitedContrib(quantity, price)
  const type = getGiftType(gift)
  return { ...gift, stillNeeds, purchased, type, leftToDeposit, giftContributors, unlimitedContributions }
})
