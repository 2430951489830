import React from 'react'
import { giftTypes } from '../../../../consts'
import { getCompletionProgress, getContributionForPrice, t } from '../../../../utils'
import ProgressLine from '../ProgressLine/ProgressLine'
import styles from "./GiftDescription.module.scss"

const GiftDescription = ({ description, price, contributors, currencySymbol, stillNeeds, purchased, quantity, shouldDisplayProgress, type }) => {
  const cutZeros = (number) => {
    let formatedNumber = number
    const numberAsString = number.toString();
    const parts = numberAsString.split('.')
    if (parts[1] === "00") {
      formatedNumber = parts[0]
    }
    return formatedNumber
  }
  const getCorrectLabel = (quantity) => {
    const n = getContributionForPrice(contributors)

    if (quantity > 1) return `${t().requested}:  ${cutZeros(n.toFixed(2))} ${currencySymbol}`
    return `${t().received}: ${cutZeros(n.toFixed(2))} ${currencySymbol}`
  }
  return (
    <div className={styles.listItemInfoDescription}>
     {description && <div className={styles.listItemInfoDescriptionTextWrapper}>
        <div className={styles.listItemInfoDescriptionText}>
          {description} 
        </div>
        {!purchased && <div className={styles.listItemInfoDescriptionButton}>{t().readMore}</div>}
      </div>}
      <ProgressLine quantity={quantity} stillNeeds={stillNeeds}
        progress={getCompletionProgress(contributors, quantity, stillNeeds, price)}
        shouldDisplay={shouldDisplayProgress} />

      <p className={styles.listItemInfoDescriptionPrice}>
        {type === giftTypes.LIMITED_PRICE && getCorrectLabel(quantity)}
      </p>

      {(type === giftTypes.LIMITED_PRICE) && !purchased && <p className={styles.listItemInfoDescriptionAmount}>

        {t().stillMissing}: {cutZeros((price - getContributionForPrice(contributors)).toFixed(2))} {currencySymbol}
      </p>}
    </div>
  )
}

export default GiftDescription
