import React from 'react'
import { t } from '../../utils'
import { ReactComponent as Diamond } from '../dimont.svg'
import styles from './ProgressMeter.module.scss'

class ProgressMeter extends React.Component {
  constructor(props) {
    super(props)

    const { radius, stroke } = this.props

    this.normalizedRadius = radius - stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI
    this.state = {
      progress: 0
    }
  }

  componentDidMount() {
    if (this.props.progress && this.props.progress > 0) {
      const interval = setInterval(() => {
        this.setState({ progress: this.state.progress + 1 })
        if (this.state.progress >= this.props.progress || this.state.progress === 100) {
          clearInterval(interval)
        }
      }, 10)
    }
  }

  render() {
    const { radius, stroke, daysToWedding } = this.props
    const { progress } = this.state
    const strokeDashoffset = this.circumference - progress / 100 * this.circumference

    return (
      <div className={styles.progressMeterWrapper}>
        <div className={styles.progressValue}>
          <Diamond/>
          <div>
            <div className={styles.daysLeftCount}>{Math.max(0, daysToWedding)}</div>
            <div className={styles.daysLeft}>{t().daysLeft}</div>
          </div>
        </div>
        <svg
          className={styles.progressCircle}
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            stroke="#b2d1cc"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{ strokeDashoffset }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    )
  }
}

export default ProgressMeter


