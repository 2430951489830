import React from 'react'
import styles from './ReadMoreButton.module.scss'

const ReadMoreButton = ({onClickHandler}) => {
    return (
        <span onClick={onClickHandler}>
            ...<br/>
            <span className={styles.readMoreButton}>Read more</span>
        </span>
            );
};

export default ReadMoreButton
