import cx from 'classnames'
import React from 'react'
import { giftTypes } from '../../consts'
import GiftDescription from "./components/GiftDescription/GiftDescription"
import GiftTitle from "./components/GiftTitle/GiftTitle"
import ImageWrapper from "./components/ImageWrapper/ImageWrapper"
import Info from "./components/Info/Info"
import TagsWrapper from "./components/TagsWrapper/TagsWrapper"
import styles from './GiftItem.module.scss'

export const GiftItem = ({ onConfirmClickHandler, onClickHandler, data: { id, description, imageUrl, quantity, title, websiteUrl, stillNeeds, purchased, type, unlimitedContributions, price, giftContributors, currencySymbol } }) => {
  return (
    <div
      className={cx(styles.listItem, { [styles.listItemPurchased]: purchased })}
      onClick={() => onClickHandler(id, type)}>
      <ImageWrapper
        imageUrl={imageUrl}
        purchased={purchased}
      />
      {title && <GiftTitle title={title} />}
      <GiftDescription
        price={price}
        contributors={giftContributors}
        currencySymbol={currencySymbol}
        stillNeeds={stillNeeds}
        purchased={purchased}
        quantity={quantity}
        type={type}
        description={description}
        shouldDisplayProgress={(type === giftTypes.LIMITED_PRICE || type === giftTypes.LIMITED_QUANTITY) && !purchased && quantity !== 1}
      />
      <Info
        purchased={purchased}
        id={id}
        type={type}
        websiteUrl={websiteUrl}
        onConfirmClickHandler={onConfirmClickHandler}
        price={price}
        quantity={quantity}
        stillNeeds={stillNeeds} />
      <TagsWrapper
        unlimitedContributions={unlimitedContributions}
        purchased={purchased}
        quantity={quantity}
        price={price}
        giftContributors={giftContributors}
        currencySymbol={currencySymbol}
        stillNeeds={stillNeeds}
      />
    </div>
  )
}
