import React, { useState } from 'react'
import { ReactComponent as FacebookLoginLogo } from './f-logo-rgb-white-1024.svg'
import { ReactComponent as GoogleLoginLogo } from './google-pay-g-pay-logo.svg'
import { auth, facebookProvider, googleProvider } from '../../utils'
import styles from '../PurchasedGiftModal.module.scss'
import cx from "classnames";
import { t } from '../../utils'

export const ConfirmSection = ({ onSubmit }) => {
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [email, setEmail] = useState()

  const [firstnameError, setFirstnameError] = useState()
  const [lastnameError, setLastnameError] = useState()
  const [emailError, setEmailError] = useState()

  const signInWith = (provider) => {
    auth.signInWithPopup(provider).then((res) => {
      const { user } = res
      let { email, displayName } = user
      if(!email) {
        email = user.providerData[0]?.email
      }
      const arrayName = displayName.split(' ')
      onSubmit({ lastname: arrayName[1], firstname: arrayName[0], email })
    })
  }

  const handleSubmit = () => {
      if(!firstname) setFirstnameError(t().require)
      if(!lastname) setLastnameError(t().require)
      if(!email) setEmailError(t().require)
      else if(!/\S+@\S+\.\S+/.test(email)) {
          setEmailError(t().invalidEmail)
      } else onSubmit({ lastname, firstname, email })
  }

  return (
    <div className={styles.popupContent}>
      <div className={styles.firstRow}>
        <div className={cx(styles.popupInput, { [styles.onError]: firstnameError })}>
          <label form='fistName'>{t().firstName}</label>
          <input id='fistName' value={firstname} onChange={(e) => {
              if(!e.target.value) setFirstnameError(t().require)
              else setFirstnameError(null)
              setFirstname(e.target.value);
          }} />
          <div className={styles.inputError}>{firstnameError}</div>
        </div>
        <div className={cx(styles.popupInput, { [styles.onError]: lastnameError })}>
          <label form='lastName'>{t().lastName}</label>
          <input id='lastName' value={lastname} onChange={(e) => {
              if(!e.target.value) setLastnameError(t().require)
              else setLastnameError(null)
              setLastname(e.target.value);
          }} />
          <div className={styles.inputError}>{lastnameError}</div>
        </div>
      </div>
      <div className={cx(styles.popupInput, { [styles.onError]: emailError })}>
        <label form='email'>{t().email}</label>
        <input id='email' value={email} onChange={(e) => {
            if(!e.target.value) setEmailError(t().require)
            else if(!/\S+@\S+\.\S+/.test(email) && emailError) setEmailError(t().invalidEmail)
            else setEmailError(null)
            setEmail(e.target.value);
        }} />
        <div className={styles.inputError}>{emailError}</div>
      </div>
      <div className={styles.buttonWrapper}>
        <button onClick={handleSubmit} className={styles.popupButton}>{t().confirm}</button>
      </div>
      <div className={styles.socialButtonHelperText}>{t().useSocialMedia}</div>
      <div className={styles.socialButtonsWrapper}>
        <div className={styles.facebookLogin} onClick={() => signInWith(facebookProvider)}><FacebookLoginLogo className={styles.socialImg} /><span>Facebook</span></div>
        <div className={styles.googleLogin} onClick={() => signInWith(googleProvider)}><GoogleLoginLogo  className={styles.socialImg}/><span>Google</span></div>
      </div>
    </div>)
}
